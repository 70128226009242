<template>
  <div>
    <KTCard ref="preview" v-bind:title="'Edit'">
      <template v-slot:title>
        <h3 class="card-label">
          {{ $t("STOCK_DISCREPANCY") }}
        </h3>
      </template>
      <template v-slot:body>
        <b-row>
          <StockInfoPanels
            v-if="!firstLoader"
            :number="stockPurchaseOrder.id"
            :fromStoreName="stockPurchaseOrder.toStoreName"
            :toStoreName="stockPurchaseOrder.fromStoreName"
            :userName="stockPurchaseOrder.userName"
          >
          </StockInfoPanels>
          <b-col :sm="12">
            <StockPurchaseOrderDiscrepancyItemsTable
              v-if="
                !firstLoader && _.size(stockPurchaseOrder.requestedItems) > 0
              "
              :headers="dynamicHeaders"
              :items="stockPurchaseOrder.requestedItems"
              :fromStoreName="stockPurchaseOrder.toStoreName"
              :toStoreName="stockPurchaseOrder.fromStoreName"
              :showTotalPrice="showTotalPrice"
              :action="action"
            >
            </StockPurchaseOrderDiscrepancyItemsTable>
          </b-col>
        </b-row>
        <b-form>
          <SkeletonLoaderMain :visible="firstLoader"></SkeletonLoaderMain>
          <v-overlay :value="!firstLoader && isLoadingStock == true">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
          </v-overlay>
        </b-form>
      </template>
      <template v-slot:foot>
        <div
          v-show="_.size(errors) > 0"
          role="alert"
          class="alert alert-danger"
        >
          <div class="alert-text" v-for="(error, i) in errors" :key="i">
            {{ error }}
          </div>
        </div>
        <BackButton></BackButton>
      </template>
      <template v-slot:toolbarfoot>
        <div class="example-tools justify-content-center">
          <SaveButton
            v-if="stockPurchaseOrder.statusId == 5"
            :isLoading="isLoadingStock"
            v-on:save="solveDiscrepancyStockPurchaseOrder($event)"
          ></SaveButton>
        </div>
      </template>
    </KTCard>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { FETCH_STOCK_PURCHASE_ORDER_DISCREPANCY } from "@/modules/stock/store/stock.module";

import SaveButton from "@/view/content/forms/components/SaveButton.vue";

import StockInfoPanels from "@/modules/stock/components/StockInfoPanels.vue";

import StockPurchaseOrderDiscrepancyItemsTable from "@/modules/stock/components/purchase-orders/StockPurchaseOrderDiscrepancyItemsTable.vue";
import { SOLVE_DISCREPANCY_STOCK_PURCHASE_ORDER } from "@/modules/stock/store/stock.module";
import AuthService from "@/core/services/auth.service";

import KTCard from "@/view/content/Card.vue";
import { mapGetters, mapState } from "vuex";

export default {
  name: "StockPurchaseOrderDiscrepancy",
  mixins: [],
  data() {
    return {
      errors: {},
      action: "discrepancy",
      firstLoader: true,
      stockPurchaseOrder: {},
      showTotalPrice: false,
      startHeaders: [
        {
          text: this.$t("ITEM"),
          align: "start",
          filterable: true,
          value: "name"
        }
      ],
      endHeaders: []
    };
  },
  components: {
    KTCard,
    StockInfoPanels,
    StockPurchaseOrderDiscrepancyItemsTable,
    SaveButton
  },
  mounted() {
    let vm = this;
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: "Stock purchase order discrepancy list",
        route: { name: "stock-purchase-orders-list-discrepancy" }
      }
    ]);

    vm.getStockPurchaseOrderDiscrepancy();
  },
  computed: {
    ...mapGetters(["isLoadingStock", "currentUser"]),
    ...mapState({
      errors: (state) => state.auth.errors
    }),
    dynamicHeaders() {
      let vm = this;
      let dynamicHeaders = vm.startHeaders;

      let addHeaders = [
        {
          text: this.$t("STOCK_PURCHASE_ORDER_REQUESTED_UNIT_VALUE"),
          value: "unitValue",
          sortable: true
        },
        {
          text: this.$t("LOTS"),
          value: "poItems",
          sortable: true
        }
      ];
      //Add headers if needed between start and endheaders as it is in StockActionReview.vue

      dynamicHeaders = [...dynamicHeaders, ...addHeaders, ...vm.endHeaders];
      return dynamicHeaders;
    },
    selectedItems() {
      let vm = this;
      let requestedItems = vm.stockPurchaseOrder.requestedItems;
      const filteredData = requestedItems.flatMap((item) => {
        return item.poItems
          .filter((poItem) => poItem.unitValue > 0)
          .map((poItem) => {
            return {
              poItemId: poItem.id,
              inReceiverUnitValue: poItem.inReceiverUnitValue,
              inSenderUnitValue: poItem.inSenderUnitValue
            };
          });
      });
      return filteredData;
    },
    payload() {
      let vm = this;
      let payload = {};
      payload.stockPurchaseOrderId = vm.stockPurchaseOrder.id;
      payload.items = vm.selectedItems;
      return payload;
    }
  },
  methods: {
    getStockPurchaseOrderDiscrepancy() {
      let vm = this;
      let id = this.$route.params.id;
      this.$store
        .dispatch(FETCH_STOCK_PURCHASE_ORDER_DISCREPANCY, id)
        .then((data) => {
          if (data.data.statusId != 5 && data.data.discrepancy != 1) {
            vm.$notify({
              group: "notify",
              type: "error",
              text: "Nout Found"
            });
            this.$router.push({
              name: "stock-purchase-orders-list-discrepancy"
            });
          } else {
            // vm.$notify({
            //   group: "notify",
            //   type: "success",
            //   title: "<i class='flaticon2-checkmark'></i> Success",
            //   text: data.message
            // });
            vm.firstLoader = false;
            vm.$nextTick(function () {
              vm.stockPurchaseOrder = data.data;

              const updatedStockData = vm.stockPurchaseOrder.requestedItems.map(
                (obj) => {
                  return {
                    ...obj,
                    poItems: obj.poItems.map((poItem) => {
                      let inSenderUnitValue = this.formatItemField(
                        poItem,
                        Math.abs(
                          parseFloat(poItem.missedUnitValue) +
                            parseFloat(poItem.returnedUnitValue)
                        )
                      );
                      let inReceiverUnitValue = this.formatItemField(
                        poItem,
                        Math.abs("0.000")
                      );
                      return {
                        ...poItem,
                        inSenderUnitValue: inSenderUnitValue,
                        inReceiverUnitValue: inReceiverUnitValue
                      };
                    })
                  };
                }
              );

              // Overwrite stockData with the updated array
              vm.stockPurchaseOrder.requestedItems = updatedStockData;
            });
          }
        })
        .catch((response) => {
          // if (response.status === 404) {
          vm.$notify({
            group: "notify",
            type: "error",
            text: response.data.message
          });
          this.$router.push({ name: "stock-purchase-orders-list-discrepancy" });
          // }
        });
    },
    solveDiscrepancyStockPurchaseOrder() {
      let vm = this;
      let message = vm.$t("PLEASE_CONFIRM");
      this.$dialog
        .confirm(message)
        .then(function () {
          let payload = vm.payload;
          vm.$store
            .dispatch(SOLVE_DISCREPANCY_STOCK_PURCHASE_ORDER, payload)
            .then((data) => {
              vm.errors = data.data.errors;
              vm.$notify({
                group: "notify",
                type: "success",
                title: "<i class='flaticon2-checkmark'></i> Success",
                text: data.message
              });
              AuthService.refreshUserData();
              vm.$router.push({
                name: "stock-purchase-orders-list-discrepancy"
              });
            })
            .catch(function (response) {
              vm.errors = response.data.errors;
              vm.$notify({
                group: "notify",
                type: "error",
                title: "Error",
                text: response.data.message
              });
              vm.getStockPurchaseOrderPrepare();
            });
        })
        .catch(function () {});
    },
    formatItemField(poItem, value) {
      if (poItem.unitNumberType == 2) {
        value = Math.round(value);
      } else {
        value = this.$options.filters.formatUnitValue(value);
      }
      return value;
    }
  }
};
</script>
