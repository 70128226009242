<template>
  <button
    @click="$emit('save')"
    class="btn btn-success font-weight-bolder mr-2"
    :class="isLoading ? 'spinner spinner-right' : ''"
    :disabled="isLoading"
  >
    <i class="far fa-save"></i> {{ $t("SAVE") }}
  </button>
</template>
<script>
export default {
  name: "SaveButton",
  inheritAttrs: false,
  props: {
    /**
     * Loader Spinner
     */
    isLoading: Boolean,
    /**
     * Default action
     * e.g. next, continue, exit
     */
    defaultAction: String
  },
  data() {
    return {};
  },
  components: {},
  mounted() {},
  computed: {},
  methods: {}
};
</script>
